import { createBrowserRouter } from 'react-router-dom'

import Root from './page/Root'
// import Index from './page/Index'
import Survey from './page/Survey'
import EmailVerification from './page/EmailVerification'
import NotFound from './page/NotFound'
import Redeemed from './page/Redeemed'

export default createBrowserRouter([
    {
        path: '*',
        name: 'notFound',
        element: <NotFound />,
    },
    {
        path: 'not_found',
        name: 'not_found',
        element: <NotFound />,
    },
    {
        path: '/:id',
        element: <Root />,
        children: [
            {
                path: '/:id/survey',
                name: 'event',
                element: <Survey />,
            },
            {
                path: '/:id/email_verification',
                name: 'email_verification',
                element: <EmailVerification />,
            },
            {
                path: '/:id/redeemed',
                name: 'redeemed',
                element: <Redeemed />,
            },
        ],
    },
])
