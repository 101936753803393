import axios from 'axios'
import config from '../config'
import { get } from 'lodash'

export const http = axios.create({
    baseURL: get(config, 'baseURL', 'http://localhost:8000'),
    timeout: get(config, 'timeout', 30000),
    headers: { 'Content-Type': 'application/json' },
})

export const getStatus = (id) => {
    return http.get(`query_redemption/${id}`)
}

export const formSubmit = (id, data) => {
    return http.post(`survey_submit/${id}`, data)
}

export const resendVerificationCode = (id) => {
    return http.post(`resend_verification_code/${id}`)
}

export const emailVerification = (id, data) => {
    return http.post(`email_verification/${id}`, data)
}
