import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Card, TextField, MenuItem, CardContent, FormControlLabel, Checkbox, FormControl, FormGroup, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { map, forEach, isEmpty, find, get } from 'lodash'
import { formSubmit } from '../utils/apiService'

export default function Survey () {
    const [termsOfUse, setTermsOfUse] = useState(false)
    const [promotion, setPromotion] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setErrors] = useState([])
    const [submitError, setSubmitError] = useState(false)
    const [reminder, setReminder] = useState(true)
    const { t } = useTranslation('survey')
    const { register, handleSubmit } = useForm()
    const { id } = useParams()
    const navigate = useNavigate()

    const options = useMemo(() => {
        return {
            age: [
                { title: '16-24 years old', value: '16-24' },
                { title: '25-34 years old', value: '25-34' },
                { title: '35-44 years old', value: '35-44' },
                { title: '45-54 years old', value: '45-54' },
                { title: 'Above 55 years old', value: '55+' },
            ],
            sells_priority: [
                { title: 'Yes, electricity plans!', value: 'electricity' },
                { title: 'Heard of PacificLight, but not sure what PacificLight sells.', value: 'heard' },
                { title: 'No, never heard of PacificLight', value: 'no' },
            ],
        }
    })

    const onSubmit = async (data) => {
        setLoading(true)
        setErrors([])
        setSubmitError(false)

        let validate = true

        forEach(data, (value, key) => {
            switch (key) {
                case 'email':
                    const regForEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
                    if (!regForEmail.test(value)) {
                        setErrors(prev => [...prev, { key, message: `invalid email` }])
                        validate = false
                    }
                case 'full_name':
                case 'age':
                case 'sells_priority':
                    if (isEmpty(value)) {
                        setErrors(prev => [...prev, { key, message: `required` }])
                        validate = false
                    }
                    break
            }
        })

        if (!validate) {
            setLoading(false)
            return
        }

        try {
            const payload = {
                form_data: {
                    full_name: data.full_name,
                    age: data.age,
                    sells_priority: data.sells_priority,
                    email: data.email,
                    terms: termsOfUse,
                    promotion: promotion,
                },
            }
            const result = await formSubmit(id, payload)
        } catch (error) {
            setSubmitError(get(error, 'response.data.message', 'Something went wrong'))
        } finally {
            setLoading(false)
        }

        navigate(`../email_verification`)
    }

    if (reminder) {
        return (
            <div className="w-100 h-auto mx-auto align-middle p-4 container">
                <Card className="h-full">
                    <CardContent className="flex flex-col justify-center self-center h-full p-4 text-xl text-center overflow-auto">
                        {/* <img src="/TGC_logo.png" className="mx-auto my-0 w-12/12"/> */}
                        <div className="w-100 mx-auto text-center text-3xl mb-6">{ `PacificLight` }</div>
                        <div className="font-bold" style={{color: "rgb(108, 190, 76)"}}> 
                            {
                                t('reminder', { returnObjects: true }).map((sentence, index) => (
                                    <>
                                        <span key={index}>{sentence}</span><br/><br/>
                                    </>
                                ))
                            }
                        </div>
                        <br/>
                        <Button variant="contained" onClick={ () => { setReminder(false) } } style={{backgroundColor: "rgb(40, 183, 241)"}}>{t('continue')}</Button>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <div className="w-100 h-auto mx-auto align-middle p-4 container">
            <Card className="h-full">
                <CardContent className="flex flex-col justify-center self-center h-full p-4 text-xl text-center overflow-auto">

                    <div className="w-100 mx-auto text-center text-3xl mb-6">{ `PacificLight` }</div>
                    <div className="flex-1">
                        <form className="flex flex-col h-full" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                margin="normal"
                                id="full_name"
                                name="full_name"
                                label="Full Name"
                                error={find(error, { key: 'full_name' }) ? true : false}
                                helperText={find(error, { key: 'full_name' }) ? find(error, { key: 'full_name' }).message : ''}
                                required
                                {...register('full_name')}
                            />

                            <TextField
                                margin="normal"
                                id="email"
                                name="email"
                                label="Email"
                                error={find(error, { key: 'email' }) ? true : false }
                                helperText={find(error, { key: 'email' }) ? find(error, { key: 'email' }).message : '' }
                                required
                                {...register('email')}
                            />

                            <TextField
                                style={{ whiteSpace: 'normal' }}
                                margin="normal"
                                id="age"
                                name="age"
                                label="What's is your age?"
                                error={find(error, { key: 'age' }) ? true : false }
                                helperText={find(error, { key: 'age' }) ? find(error, { key: 'age' }).message : '' }
                                select required
                                {...register('age')}
                            >
                                { map(options.age, (option, index) => <MenuItem key={'age_' + index} value={option.value} style={{whiteSpace: 'normal'}}>{option.title}</MenuItem>) }
                            </TextField>

                            <TextField
                                style={{ whiteSpace: 'normal' }}
                                margin="normal"
                                id="sells_priority"
                                name="sells_priority"
                                label="Do you know what PacificLight Energy sells prior to this event?"
                                error={find(error, { key: 'sells_priority' }) ? true : false }
                                helperText={find(error, { key: 'sells_priority' }) ? find(error, { key: 'sells_priority' }).message : '' }
                                select required
                                {...register('sells_priority')}
                            >
                                { map(options.sells_priority, (option, index) => <MenuItem key={'sells_priority_'+index} value={option.value} style={{whiteSpace: 'normal'}}>{option.title}</MenuItem>) }
                            </TextField>

                            <FormControl component="fieldset" sx={{my: 1}}>
                                <FormGroup sx={{ mx: 3 }} >
                                    { map(options.interested_product_type, (option, index) => 
                                        <FormControlLabel 
                                            key={'interested_product_type-'+index}
                                            control={
                                                <Checkbox 
                                                    value={option.value}
                                                    {...register('products_interested', {required: true})}
                                                />} 
                                            label={option.name} 
                                        />
                                    )}
                                </FormGroup>
                            </FormControl>

                            <FormControlLabel
                                className="mb-4"
                                style={{ alignItems: 'start' }}
                                labelPlacement="end"
                                label={<span className="block text-left" dangerouslySetInnerHTML={{__html: `I have read, fully understood and agree to <a style="text-decoration:underline;font-weight:bold;" href="https://www.pacificlight.com.sg/notices-terms/privacy-terms ">PacificLight’s Privacy Notice</a> and the <a style="text-decoration:underline;font-weight:bold;" href="https://pacificlight.com.sg/promotions/fromyouroldfriend">Terms and Conditions</a> relating to this campaign. I consent to the collection, use, disclosure and renteion of my personal data by PacificLight and its third-party service providers for the gift redemption.`}}/>}
                                control={
                                    <Checkbox
                                        style={{ paddingTop: '0' }}
                                        checked={termsOfUse}
                                        onChange={(e) => setTermsOfUse(e.target.checked)}
                                        name="terms_of_use"
                                        color="primary"
                                    />
                                }
                            />

                            <FormControlLabel
                                className="mb-4"
                                style={{ alignItems: 'start' }}
                                labelPlacement="end"
                                label={<span className="block text-left" dangerouslySetInnerHTML={{__html: `I wish to receive marketing, advertising and promotional materials via emails from PacificLight Energy and consent to PacificLight and its third party service providers collecting, using, disclosing, storing and retaining my personal data in accordance to PacificLight’s Privacy Notice for the stated purpose.`}}/>}
                                control={
                                    <Checkbox
                                        style={{ paddingTop: '0' }}
                                        checked={promotion}
                                        onChange={(e) => setPromotion(e.target.checked)}
                                        name="promotion"
                                        color="primary"
                                    />
                                }
                            />

                            { submitError && <Alert severity="error" className="mb-4">{ submitError }</Alert> }
                            <div className="mt-6 w-full">
                                <LoadingButton type="submit" variant="contained" disabled={!termsOfUse || !promotion} loading={loading} className="w-full">{ t('submit') }</LoadingButton>
                            </div>
                        </form>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
