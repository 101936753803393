import { useState, useEffect } from 'react'
import { Outlet, useParams, useNavigate } from 'react-router-dom'
import { getStatus } from '../utils/apiService'
import { CircularProgress } from '@mui/material';
import { get } from 'lodash'

export default function Root() {
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()

    // useEffect(() => {
    //     const getQuestionnaireStatus = async () => {
    //         try {
    //             const { data } = await getStatus(id)

    //             switch (get(data, 'data.status_code', false)) {
    //                 case 0:
    //                     navigate(`survey`)
    //                     break
    //                 case 1:
    //                     navigate(`redeemed`)
    //                     break
    //                 case 2:
    //                     navigate(`email_verification`)
    //                     break
    //                 default:
    //                     navigate(`not_found`)
    //             }
    //         } catch (error) {
    //             navigate(`not_found`)
    //         } finally {
    //             setLoading(false)
    //         }
    //     }

    //     getQuestionnaireStatus()
    // }, [])

    if (loading) {
        return (
            <div className="w-screen h-screen">
                <div className="absolute top-1/2 left-1/2 h-fit w-fit -translate-x-1/2 -translate-y-1/2">
                    <CircularProgress size={80} />
                </div>
            </div>
        )
    }

    return (
        <>
            <Outlet />
        </>
    )
}
