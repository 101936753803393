import { useTranslation } from 'react-i18next'
import { Card, CardContent } from '@mui/material'

export default function Redeemed () {
    const { t } = useTranslation('redeemed')

    return (
        <div className="w-100 h-auto mx-auto align-middle p-4 container">
            <Card className="h-full">
                <CardContent className="flex flex-col justify-center self-center h-full p-4 text-xl text-center overflow-auto">
                    <span>{ 'Congratulations!' }</span>
                </CardContent>
            </Card>
        </div>
    )
}
